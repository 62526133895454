
import { useRouter } from "vue-router";
import {
  defineComponent,
  inject,
  ref,
  watch,
  reactive,
  Ref,
  PropType,
} from "vue";
import PhoneNumberComponent from "@hd2/common/src/components/PhoneNumber.vue";
import { useI18n } from "vue-i18n";
import { AxiosStatic } from "axios";
import { notification } from "ant-design-vue";
import { RuleObjectExt, MakeAppointmentData } from "../../types";
import { PhoneNumber } from "@hd2/common/types";
import { Form } from "ant-design-vue";
import { useStore } from "../store";

const useForm = Form.useForm;

interface Model {
  phoneNumber: PhoneNumber;
}

export const NoDoctorsComponent = defineComponent({
  components: {
    "phone-number": PhoneNumberComponent,
  },
  props: {
    data: {
      type: Object as PropType<MakeAppointmentData>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const http = inject("http") as AxiosStatic;
    const { t } = useI18n();
    const store = useStore();

    const valid: Ref<boolean> = ref(false);
    const loading: Ref<boolean> = ref(false);
    const model: Model = reactive({
      phoneNumber: {
        number: "",
        prefix: "+48",
        pattern: "",
      },
    });

    const rules: Record<string, Array<RuleObjectExt>> = reactive({
      phoneNumber: [
        {
          validator: (rule, value) => {
            return new Promise((resolve, reject) => {
              if (!value) {
                reject(t("NO_DOCTORS.PHONE_NUMBER_REQUIRED"));
              } else if (!value.number.match(value.pattern)) {
                reject(t("NO_DOCTORS.PHONE_NUMBER_INVALID"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    });

    const formTemplate = ref();
    const form = useForm(model, rules);

    const onPhoneNumberChange = () => {
      formTemplate.value.validate("phoneNumber");
    };

    const callBack = async () => {
      try {
        loading.value = true;
        await http.post("patient-portal/api/call-back", {
          phoneNumber: model.phoneNumber,
        });
        loading.value = false;
        notification.open({
          message: t("NO_DOCTORS.SEND_SUCCESS"),
          class: "success",
        });
        router.push({ name: "Visits" });
      } catch {
        notification.open({
          message: t("ERROR.4191"),
          class: "error",
        });
        loading.value = false;
      }
    };

    const makeNewAppointment = () => {
      router.push({
        name: "MakeAppointment",
        params: {
          data: JSON.stringify(props.data),
          nfzChoosen: store.getters.isForNfz,
        },
      });
    };

    watch(
      model,
      () => {
        form
          .validate()
          .then(() => {
            valid.value = true;
          })
          .catch(() => {
            valid.value = false;
          });
      },
      { deep: true }
    );

    return {
      valid,
      rules,
      callBack,
      t,
      model,
      onPhoneNumberChange,
      makeNewAppointment,
      loading,
      formTemplate,
    };
  },
});

export default NoDoctorsComponent;
