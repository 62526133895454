import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8eac702e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "no-doctors" }
const _hoisted_2 = { class: "no-doctors__box" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { style: {"margin-bottom":"30px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_phone_number = _resolveComponent("phone-number")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", {
        innerHTML: _ctx.t('NO_DOCTORS.HEADER')
      }, null, 8 /* PROPS */, _hoisted_3),
      _createVNode(_component_a_divider),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t("NO_DOCTORS.DESC")), 1 /* TEXT */),
      _createVNode(_component_a_form, {
        class: "no-doctors__form",
        ref: "formTemplate",
        model: _ctx.model,
        rules: _ctx.rules,
        onSubmit: _ctx.callBack,
        autocomplete: "off"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            class: "no-doctors__phone-number-number",
            name: "phoneNumber"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_phone_number, {
                placeholder: _ctx.t('PHONE_NUMBER.NUMBER'),
                onChange: _ctx.onPhoneNumberChange,
                value: _ctx.model.phoneNumber,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.phoneNumber) = $event))
              }, null, 8 /* PROPS */, ["placeholder", "onChange", "value"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_a_button, {
            class: "no-doctors__button no-doctors__button--call-back",
            type: "primary",
            style: {"margin-top":"80px"},
            loading: _ctx.loading,
            "html-type": "submit",
            disabled: !_ctx.valid
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("NO_DOCTORS.SUBMIT")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading", "disabled"]),
          _createVNode(_component_a_button, {
            style: {"margin-top":"22px"},
            class: "no-doctors__button no-doctors__button--return",
            onClick: _ctx.makeNewAppointment
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("NO_DOCTORS.CANCEL")), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model", "rules", "onSubmit"])
    ])
  ]))
}